@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  box-sizing: border-box;
  font-family: 'Righteous', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/images/bg-welcome.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-submit {
  font-family: 'Mulish';
  font-size: 18px;
  background-color:#E72C35;
  border-color:#E72C35;
  color: white;
  font-weight: bold;
}

.text-user {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  color: #414141;
}

.text-eligible {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  color: #CC0000;
}

.text-company {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  color: #F5F9F0;
  font-size: 35px;
}

.text-concurrent {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: #FF9A00;
}

.text-room {
  font-size: 50px;
  color:#454545;
}

.text-breakout-room-concurrent {
  font-size: 40px;
  color: #FF9A00;
}